/**
 * Common Constants
 */
const SharedConstants = {
  KEY_CHAR_CODE: {
    SPACE: 32,
    ENTER: 13,
    PLUS: 43,
    MINUS: 45,
  },
  CMS_CONTENT_TYPES: {
    LOGO_ICON: 'logoicon',
    MULTI_TEXT: 'multiTypeText',
    CALL_TO_ACTION: 'callToAction',
    NAVIGATION_LINK: 'navigationLink',
  },
  VIDEO: 'VIDEO',
  DROPDOWN_DEFAULT_VALUE: '- Select One -',
  PICK_UP_COLLECTION_METHOD: 'pickUpCollectionMethod',
  DRIVE_THRU_PICK_UP: 'driveThruPickUp',
  CURB_SIDE_PICK_UP: 'curbsidePickUp',
  IN_STORE_PICK_UP: 'inStorePickUp',
  CAR_NUMBER_PLATE: 'carNumberPlate',
};

export const LANGUAGE_CHANGED = 'LanguageChanged';
export const PROD = 'PROD';
export const FOOTER = 'footer';
export const CHANGE_LANGUAGE_HERE = 'changeLanguageHere';
export const CHANGE_LANGUAGE = 'changeLanguage';
export const STORE_MENU = 'STORE_MENU';
export const ADD_TO_CART_DEEPLINK_TEXT = 'addtocart';
export const SIGN_IN = 'Sign In';
export const TYPE_DELIVERY = 'DELIVERY';
export const TYPE_TAKE_WAY = 'PICKUP';
export const TYPE_CHRISTMAS_ORDERING = 'CHRISTMAS-ORDERING';
export const SELECT_TEXT = 'Select';
export const TIME_TEXT = 'time';
export const TYPE_DINE_IN = 'DINE-IN';
export const TYPE_CATERING = 'CATERING';
export const TYPE_TAKEAWAY_TEXT = 'TAKEAWAY';
export const TYPE_CHRISTMAS_ORDERING_TEXT = 'CHRISTMAS ORDERING';
export const PICK_UP = 'pickup';
export const BRAZE_ACCOUNT_MSG = 'BRAZE_ACCOUNT_MSG';
export const DINE_IN = 'dine-in';
export const DELIVERY = 'delivery';
export const CATERING = 'catering';
export const CHRISTMAS_ORDERING = 'christmas-ordering';
export const TAKEAWAY_TEXT = 'Pickup';
export const CHRISTMAS_ORDERING_TEXT = 'Christmas Ordering';
export const PHONE_NUMBER = 'phoneNumber';
export const OTHER = 'Other';
export const TAKEAWAY_TEXT_SELECT_CONTENT = 'Pick up';
export const DELIVERY_TEXT = 'Delivery';
export const DINE_IN_TEXT = 'Dine in';
export const DEFAULT_IMAGE_PATH = 'no-image.jpg';
export const HOME_PAGE = 'Homepage';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const MENU_PAGE = 'Menu';
export const NUTRITION_ALLERGEN = 'NutritionAllergen';
export const SEO_MENU_CATEGORY_PRODUCT_TYPE = 'MenuItem';
export const GIFTCARDS = 'GiftCards';
export const CART = 'Cart';
export const FAQ = 'FAQ';
export const CONTACT_PAGE = 'ContactPage';
export const MENU_CATEGORY_PAGE = 'MenuCategory';
export const MENU = 'menu';
export const ASAP = 'ASAP';
export const APPLY_FREE_DELIVERY = 'ApplyFreeDelivery';
export const ADDITIONAL_CHARGES = 'Additional-Charges';
export const APPLIED = 'APPLIED';
export const DISCOUNT_CODE = 'Discount Code';
export const WICODE = 'WiCode';
export const SELECT_DELIVERY_TIME = 'Select delivery time';
export const BROWSE_MENU = 'BROWSE_MENU';
export const PRODUCT_PAGE = 'Product';
export const USER_SELECTED_LANGUAGE = 'USER_SELECTED_LANGUAGE';
export const PAGE_RELOAD = 1;
export const PDP_PAGE_URL_LENGTH = 4;
export const HOME_PAGE_CONTENT_DATAKEY = 'homeContentfulResp';
export const mockArray = [];
export const mockObject = {};
export const mockNull = null;
export const NULL = 'NULL';
export const VALID = 'Valid';
export const INVALID = 'InValid';
export const TRUE = 'true';
export const NO_NAME_EXISTS = 'No Name';
export const CONFIG_MIN_SUPPORTED_BROWSERS = 'MIN_SUPPORTED_BROWSERS';
export const UNSUPPORTED_BROWSER = 'unsupportedBrowser';
export const SOURCE_FLAG_MOBILE = 'mobile';
export const SOURCE_FLAG_DESKTOP = 'desktop';
export const AT = 'at';
export const DISPOSITIONOLD = 'dispositionOld';
export const DISPOSITIONNEW = 'dispositionNew';
export const CURRENTSTOREINFO = 'currentStoreInfo';
export const IPAD_CHECK_WIDTH = '(min-width:650px) and (max-width:1024px)';
export const CARTROUTE = 'cart';
export const SELF = '_self';
export const CURRENCY_CONSTANTS = ',00';
export const percentageSymbol = `%`
export const loggedIN = 'loggedIN';
export const ToastMSG = 'Item added to Cart';
export const ToastMSG_Items = 'Items added to Cart';
export const KFC = 'KFC';
export const availableFrom = '[0].availableHours.from';
export const availableTo = '[0].availableHours.to';
export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'EVERYDAY'];
export const days_data = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'EVERYDAY'];
export const DAYS_OBJ = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  EVERYDAY: 'EVERYDAY',
};
export const ADD_HOPE_THANK_YOU = 'addHopeThankYou';
export const ADD_HOPE = 'AddHope';
export const RESTAURANT_PAGE = 'RestaurantPage';
export const FIND_KFC = 'FindAKFC';
export const SEO_OPTIONS = 'seoOptions';
export const PLACE_SERVICE_ERROR_CODE = ['INVALID_REQUEST', 'REQUEST_DENIED', 'UNKNOWN_ERROR', 'OVER_QUERY_LIMIT'];
export const ASAP_TIMING = 'ASAP';
export const priceRoundoff_value = 100;
export const priceFixed_value = 2;
export const imageCarouselSpeedFactor = 4;
export const videoCarouselSpeed = 15;
export const TO_CATERING_NAV = 'toCatering';
export const TO_MENU_NAV = 'toMenu';
export const MENU_DRINKS = 'drinks';
export const FINDAKFC = 'findKfc';
export const GIFT_CARD = 'GiftCard';
export const PageTitle = {
  CART: 'Page_Title_Cart',
  OFFERS: 'Page_Title_Offers',
  LOGIN: 'Page_Title_Login',
  HELP: 'Page_Title_Help',
  CHECKOUT: 'Page_Title_Checkout',
  PAYMENT_FAILURE: 'Page_Title_Payment_Failure',
  PAYMENT_SUCCESS: 'Page_Title_Payment_Success',
  PAYMENT_STATUS: 'Page_Title_Payment_Status',
  OUR_ADDS: 'Page_Title_Our_Adds',
  CONTACT_US: 'Page_Title_ContactUs',
  TERMS_OF_USE: 'Page_Title_TermsOfUse',
  TERMS_CONDITIONS: 'Page_Title_Terms_Conditions',
  FAQ: 'Page_Title_Faq',
  GIFT_CARD: 'Page_Title_Gift_Card',
  PRIVACY_POLICY: 'Page_Title_Privacy_Policy',
  SIGN_OUT: 'Page_Title_Sign_out',
  ACCOUNT: 'Page_Title_Account',
  NOT_FOUND: 'Page_Title_Not_Found',
  ORDER_PROCESSING: 'Order_Processing',
  ORDER_SUMMARY: 'Order_Summary',
  CAUTION_NOTICE: 'Page_Title_Caution_Notice',
  FINDAKFC: 'findKfc',
  ADD_HOPE_THANK_YOU_Title: 'thankYou',
  ADD_HOPE: 'donate',
  PAYMENT: 'Payment',
};

export const Config = {
  TOKEN_EXPIRY_GRACE_PERIOD: 'TOKEN_EXPIRY_GRACE_PERIOD',
  USER_RESET_GRACE_TIME_MINUTES: 'USER_RESET_GRACE_TIME_MINUTES',
  USER_RESET_WARNING_GRACE_TIME_MINUTES: 'USER_RESET_WARNING_GRACE_TIME_MINUTES',
  GOOGLE_SPOT_AUTH_SCOPE: 'GOOGLE_SPOT_AUTH_SCOPE',
  HEADER_ATTR_DATA_ID: 'root-header',
  HEADER_SIGN_IN_ATTR_DATA_ID: 'header-sign-in',
  CHECKOUT_SIGN_IN_ATTR_DATA_ID: 'checkout-sign-in',
  CHECKOUT_SIGN_UP_ATTR_DATA_ID: 'checkout-sign-up',
  CHECKOUT_HELP_ICON_ATTR_DATA_ID: 'checkout-help-icon',
  VIEWPORT_AGENT_BROWSER: 'web',
  VIEWPORT_AGENT_GOOGLE: 'GPay-Microapps',
  VIEWPORT_AGENT_PHONE_PE: 'phonepe',
  VIEWPORT_AGENT_PAY_TM: 'AppContainer',
  VIEWPORT_CHANNEL_BROWSER: 'web',
  VIEWPORT_CHANNEL_GOOGLE: 'google-pay-spot',
  VIEWPORT_CHANNEL_PHONE_PE: 'phonepe-in-app',
  VIEWPORT_CHANNEL_PAY_TM: 'paytm-miniapp',
  VIEWPORT_CLASSNAME_DEBUG: 'channel-debug',
  VIEWPORT_CLASSNAME_BROWSER: 'channel-browser',
  VIEWPORT_CLASSNAME_GOOGLE: 'channel-google-pay',
  VIEWPORT_CLASSNAME_PHONE_PE: 'channel-phone-pe',
  VIEWPORT_CLASSNAME_PAY_TM: 'channel-pay-tm',
  QR_CODE_VALID_TIME_MINS: 'QR_CODE_VALID_TIME_MINS',
  Status: {
    Pass: 'SUCCESS',
    Pending: 'SUBMITTED',
    Fail: 'FAILURE',
  },
  Setting: {
    Google: {
      Source: 'https://microapps.google.com/apis/v1alpha/microapps.js',
      Nonce: null,
      Prompt: true,
    },
    Accuracy: true,
    Retry: 3,
    Interval: 500,
    Timeout: 5000,
    Expiration: 0,
    Storage: {
      Store: 'storeInfo',
    },
  },
  Debug: {
    Navigator: {
      UserAgent: {
        Browser:
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Safari/537.36',
        Google:
          'Mozilla/5.0 (Linux; Android 11; moto g fast Build/RPJS31.Q1-53-12-5; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/91.0.4472.164 Mobile Safari/537.36 [GPay-Microapps/132.1.5 (arm64-v8a_release_flutter) flutter]',
      },
    },
    Google: {
      Coordinates: {
        longitude: 78.372658,
        latitude: 17.442791,
      },
      Address: 'gachibowli, hyderabad, telangana, india',
      Name: 'isaac ewing',
      Email: 'isaac.ewing@cognizant.com',
      Phone: '9725550178',
      OrderId: 'VGUH0124248666',
      BasketId: '146258421991',
      Token: {
        Phone:
          'eyJhbGciOiJSUzI1NiIsImtpZCI6IjRmNWQ0MDA2ZDYxMzVhYTcxN2UxMzA5YzllYzM0YmE5ODljMjlhZTEiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJodHRwczovL21pY3JvYXBwcy5nb29nbGUuY29tLzE1NDM0NDQwNjQxOTQ0MjM0Mzk2IiwiaXNzIjoiaHR0cHM6Ly9taWNyb2FwcHMuZ29vZ2xlLmNvbSIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwicGhvbmVfbnVtYmVyIjoiKzEgOTcyODMyMDE3OCIsImV4cCI6MTYyNjI4MjkxOCwiaWF0IjoxNjI2Mjc5MzE4fQ.PaR80j_eFsIdNIWtRLq5Xx1nxs0c4RVRBrUvXRe6JnyqqtwYrya4wWGlqQAlpwLSpKNuVDbaHUmJV7CEkiCPslLn6hDIut9IkImBIhWoBlYFApu7ghrZIoYH9pVjswUVpNjWhv7p6U8VJ26pcunsm24AaxYTBVsEOSBv4OXyV0NuxbZeYR_agxyejZXWOnKm6TuZkaNIqqG5Q9QdUrPPvIIaKTDnmIm5AKtpydXRGgzNwNb3wjnV9L1HxAkKFrivh7PDW1dxTJ8sIen8XKgRkL3Pj4KClNVzWiiyeQT6ifGH74ilXmV4w7Q337R69hBA0nNyQ8o6t7XtQw9gsbZnOQ',
        Identity:
          'eyJhbGciOiJSUzI1NiIsImtpZCI6IjNkZjBhODMxZTA5M2ZhZTFlMjRkNzdkNDc4MzQ0MDVmOTVkMTdiNTQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2Mjc2MjkzODcsImF1ZCI6IjEwMjU4NjI4MTY3MjYtdjFwOGY0dmlqYjh0NDdncGFkaXUzMHBtNGljaGh1M3UuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTY5MjcwNTAxNjgyNDc5MDA2MTEiLCJlbWFpbCI6Im0uc2FybzE5OThAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF6cCI6IjEwMjU4NjI4MTY3MjYtdjFwOGY0dmlqYjh0NDdncGFkaXUzMHBtNGljaGh1M3UuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJuYW1lIjoiU2FyYXZhbmEgR2FuZXNoIE0iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKenJVOWFxbXJxM0ZWVnI0QXFMTkEtdXhEcWZ5VkdNOXRLSlJQbzk9czk2LWMiLCJnaXZlbl9uYW1lIjoiU2FyYXZhbmEgR2FuZXNoIiwiZmFtaWx5X25hbWUiOiJNIiwiaWF0IjoxNjI3NjI5Njg3LCJleHAiOjE2Mjc2MzMyODcsImp0aSI6ImViZWNlOTY5MzczMDVkNTIzZjcxZTY4NWMxOWMzMTdkNGVjODcwNDIifQ.NJOMLzzUffqj6ihraIQv1ahjFpFuYQ2x-Jx5ERNln736Iz-bJeS0IruGinCqOSMGIwnfJYfYR31FfHt-RRhSidj7NCGA1UiMkm9HaX1CrUPNDN44SSUNz1Qxtoeu7K6rhTV-pRFJiuQ2MVky96hl4i012_EdDLa4gEv3JqCx6aL6E08mru9-vLN7T504oZj7jreJAK6Y_5Ne2C8mrQT785_qybJAthNxSQOKjTsBc3gHzHumjvGjNZfPEtKpHmuxVu-0Jq2_F5Cq3wZd07sZkSPKqzAld_UF8xesSRGYj7I6udZEf81cAjXgyeRouUmO8I0sHToiKlzNMPAnNBAgKg',
      },
      Payment: {
        Response: {
          apiVersion: 2,
          apiVersionMinor: 0,
          paymentMethodData: {
            type: 'UPI',
            tokenizationData: {
              type: 'DIRECT',
              token:
                '{"protocolVersion":"ECV2","signature":"","signedMessage":"{\\"paymentMethod\\":\\"UPI\\",\\"paymentMethodDetails\\":{\\"payeeVpa\\":\\"yumrestaurantindia.billdesk@hdfcbank\\",\\"transactionReferenceId\\":\\"UGUH0002036496\\",\\"transactionId\\":\\"779306059769\\",\\"status\\":\\"SUCCESS\\",\\"transactionInfo\\":{\\"currencyCode\\":\\"INR\\",\\"totalPriceStatus\\":\\"FINAL\\",\\"totalPrice\\":\\"2.00\\",\\"transactionNote\\":\\"\\"}},\\"messageExpiration\\":\\"1627567541475\\"}"}',
            },
          },
        },
      },
      Order: {
        Channel: true,
        Olo: true,
      },
    },
  },
};

export const FORM_FIELD_TYPE = {
  TEXT: 'text',
  TITLE: 'title',
  LABEL: 'label',
  NOTE: 'note',
  INPUT: 'input',
  BUTTON: 'button',
  PAYMENT_TEXT_INPUT: 'paymentTextInput',
  MASK_INPUT: 'maskinput',
  CHECKBOX: 'checkbox',
  DONATION_BUTTON_GROUP: 'donationButtonGroup',
  TILES_BUTTON_GROUP: 'tilesButtonGroup',
};

export const FORM_FIELD_DATA_TYPE = {
  TEXT: 'text',
  TEL: 'tel',
  CARD_NUMBER: 'cardNumber',
  CARD_CVV: 'cvc',
  CARD_EXPIRY_DATE: 'expiryDate',
};

export const PAYMENT_TENDER_TYPES = {
  CREDIT_CARD: 'credit-card',
  GIFT_CARD: 'giftcard',
  NET_BANKING: 'net-banking',
  OTHER_WALLET: 'other-wallet',
  PAY_BY_CASH: 'pay-by-cash',
  PAYTM: 'paytm',
  PHONEPE: 'phonepe',
  GOOGLE_PAY_SPOT: 'google-pay-spot',
  UPI: 'upi',
  PAYTM_MINIAPP: 'paytm-miniapp',
  PHONEPE_SWITCH: 'phonepe-in-app',
  GOOGLE_PAY: 'googlepay',
  APPLE_PAY: 'applepay',
  PAY_PAL: 'paypal',
  PAY_PAL_CREDIT: 'paypalcredit',
  VENMO: 'venmo',
  CANAL: 'canal',
  COUPON: 'coupon',
  WICODE: 'wi-code',
  DGFT_CREDIT_CARD: 'dgft-credit-card',
};

export const PAYMENT_ICON = {
  CREDIT_CARD: ['UnionPay', 'Discover', 'DinersClub', 'Visa', 'MasterCard', 'AMEX', 'JCB'],
  PAY_PAL: 'PayPal',
  APPLE_PAY: 'ApplePay',
  GOOGLE_PAY: 'GooglePay',
};

export const ROUTE_URL = {
  HOME: '',
  HOME_PAGE: '/',
  CART: '/cart',
  CHECKOUT: '/checkout',
  GIFT_CARD: '/giftcards',
  PAYMENT_SUCCESS: '/payment-success',
  PAYMENT_FAILURE: '/payment-failure',
  ORDER_DETAILS: '/order-details',
  QR_HOMEPAGE: '/qrhome',
  FIND_STORE: '/find-store',
  MENU_PAGE: '/menu',
  CATERING_PAGE: '/catering',
  HELP: '/help',
  ACCOUNT: '/account',
  TRACK_ORDERS: '/track-orders',
  PAYMENT_STATUS_CHECK: '/payment-status-check',
  ACTIVE_ORDERS: '/active-orders',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacyPolicy',
  PROMOS_REWARDS: 'promos-rewards',
  ORDER_PROCESSING: '/order-processing',
  ORDER_SUMMARY: '/order-summary',
  RESTAURANT: '/restaurants',
  SIGNOUT: '/signout',
  CONTACT_US: '/contactus',
  CONTACT_KFC: '/contact-kfc',
  THANK_YOU: '/thankyou',
  NUTRITION_ALLERGEN: '/nutrition-allergen',
  ACCOUNT_SETTINGS: '#accountsettings',
  ORDER_HISTORY: '/order-history',
  FAQ: '/faq',
  PRIVACY_POLICY_LINK: '/privacy-policy',
  TERMS_OF_USE: '/terms-of-use',
  TERMS_CONDITIONS: '/terms-conditions',
  ADD_HOPE_THANK_YOU: '/addhope/thankyou',
  ADD_HOPE: '/addhope/donate',
  ADD_NEW_CARD: '/addnewcard',
  PROCESSING: '/processing',
  DGFT_PAYMENT_STATUS_CHECK: '/dgft-payment-status-check'
};

export const DISPOSITION_TYPES = {
  DELIVERY: 'DELIVERY',
  DINE_IN: 'DINE-IN',
  PICKUP: 'PICKUP',
  CHRISTMAS_ORDERING: 'CHRISTMAS-ORDERING',
};

export const DISPOSITION_KEYS = {
  DELIVERY: 'DELIVERY',
  DINE_IN: 'DINE-IN',
  PICKUP: 'PICKUP',
  CATERING: 'CATERING',
  CHRISTMAS_ORDERING: 'CHRISTMAS-ORDERING',
  DRIVE_THRU: 'DRIVE-THRU',
};

export const DISPOSITION_TYPES_TEXT = {
  DELIVERY: 'Delivery',
  'DINE-IN': 'Dine in',
  DINE_IN: 'Dine in',
  PICKUP: 'Pick up',
  CATERING: 'Catering',
  'CHRISTMAS-ORDERING': 'Christmas Ordering',
};
export const DISPOSITION_LIST = [...Object.values(DISPOSITION_KEYS)];

export const ORDER_STATUS = {
  STAGED: 'STAGED',
  ACCEPTED: 'ACCEPTED',
  SENT_TO_POS_COMPLETED: 'SENT-TO-POS-COMPLETED',
  CANCELLED: 'CANCELLED',
  PAYMENT_FAILED: 'PAYMENT-FAILED',
  SENT_TO_POS_FAILED: 'SENT-TO-POS-FAILED',
  CREATED: 'CREATED',
  ASSIGNED: 'ASSIGNED',
  STORE_ARRIVAL: 'STORE_ARRIVAL',
  CHECKED_IN: 'CHECKEDIN',
  PICKED_UP: 'PICKED-UP',
  OUT_FOR_DELIVERY: 'OUT FOR DELIVERY',
  ARRIVED: 'ARRIVED',
  DELIVERED: 'DELIVERED',
  CANCEL: 'CANCEL',
  REFUND: 'REFUND',
  REFUND_SUCCESS: 'REFUND-SUCCESS',
  REFUND_FAILED: 'REFUND-FAILED',
  MISC_PAYMENT_INPROGRESS: 'MISC-PAYMENT-INPROGRESS',
  MISC_PAYMENT_SUCSESS: 'MISC-PAYMENT-SUCCESS',
  MISC_PAYMENT_FAILURE: 'MISC-PAYMENT-FAILURE',
  CHECKED_IN_CLOSED: 'CHECKEDIN-CLOSED',
  CHECKED_IN_COOKING: 'CHECKEDIN-COOKING',
  CHECKED_IN_PREPARED: 'CHECKEDIN-PREPARED',
  CHECKED_IN_REMAKE: 'CHECKEDIN-REMAKE',
  CHECKED_IN_NOT_COLLECTED: 'CHECKEDIN-NOT_COLLECTED',
  CHECKED_IN_ABANDONED: 'CHECKEDIN-ABANDONED',
  READY_FOR_POS_INJECTION: 'READY-FOR-POS-INJECTION',
  UNASSIGNED_DELIVERY_CANCELLED: 'DASHER_UNASSIGNED_DELIVERY_CANCELLED',
  UNDELIVERED_DELIVERY_CANCELLED: 'DASHER_UNDELIVERED_DELIVERY_CANCELLED'
};

export const DOORDASH_DELIVERY_ORDER_STATUS = {
  DELIVERY_CREATED: 'DELIVERY_CREATED',
  DASHER_CONFIRMED: 'DASHER_CONFIRMED',
  DASHER_ENROUTE_TO_PICKUP: 'DASHER_ENROUTE_TO_PICKUP',
  RELEASE_ORDER_FOR_PREPARATION: 'RELEASE_ORDER_FOR_PREPARATION',
  DASHER_CONFIRMED_STORE_ARRIVAL: 'DASHER_CONFIRMED_STORE_ARRIVAL',
  DASHER_PICKED_UP: 'DASHER_PICKED_UP',
  DASHER_ENROUTE_TO_DROPOFF: 'DASHER_ENROUTE_TO_DROPOFF',
  DASHER_CONFIRMED_CONSUMER_ARRIVAL: 'DASHER_CONFIRMED_CONSUMER_ARRIVAL',
  DASHER_DROPPED_OFF: 'DASHER_DROPPED_OFF',
  DELIVERY_CANCELLED: 'DELIVERY_CANCELLED',
  UNDELIVERED: 'UNDELIVERED',
  UNASSIGNED: 'UNASSIGNED',
};

export const ORDER_GROUP_STATUS = {
  STAGED_ACCEPTED: 'STAGED-ACCEPTED',
  PICKUP_DELIVERY_DINE_IN: 'PICKUP-DELIVERY-DINE-IN',
  PICKUP_DELIVERY_DINE_IN_POS_COMPLETE: 'PICKUP-DELIVERY-DINE-IN-POS-COMPLETE',
  DELIVERY_DISTA_CREATE_ASSIGN: 'DELIVERY_DISTA_CREATE_ASSIGN',
  DELIVERY_DISTA: 'DELIVERY-DISTA',
  DELIVERY_DISTA_DELIVERED: 'DELIVERY_DISTA_DELIVERED',
  DELIVERY_CREATE_ASSIGN: 'DELIVERY_CREATE_ASSIGN',
  DELIVERY_ORDER_TRACKING: 'DELIVERY_ORDER_TRACKING',
  DELIVERY_DELIVERED: 'DELIVERY_DELIVERED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
};

export const LOCAL_STORAGE_KEYS = {
  WEB_APPVERSION: 'webAppVersion',
  DEFAULT_LANGUAGE: 'defaultLanguage',
  USER_LANGUAGE: 'userLanguage',
  ORDER_TRACKING: 'orderTracking',
  POST_PAYMENT_TYPE: 'postPaymentType',
  PAYMENT_STATUS: 'paymentStatus',
  DISPOSITION_OLD: 'dispositionOld',
  DISPOSITION_NEW: 'dispositionNew',
  DISPOSITION_TYPE: 'dispositionType',
  TENANT_REDUCER: 'tenantReducer',
  RETAIN_ADDRESS: 'searchStoreByAttribute',
  SERVICE_TYPE: 'serviceType',
  STORE_ID: 'storeId',
  CURRENT_STORE_INFO_STORAGE: 'currentStoreInfo',
  STORE_INFO: 'storeInfo',
  DATE_TIME_ORDER: 'dateTimeOrder',
  SAVED_TIME_ORDER: 'savedTimeOrder',
  SEARCHED_LOCATIONS: 'seachedLocations',
  CONFIG_SEARCH_SCREEN: 'configSearchScreen',
  SELECTED_TILE: 'selectedTile',
  RENDER_COMP: 'renderComp',
  PRODUCT_DETAILS: 'ProductDetails',
  PDP_DATA_FOR_UPDATE_FOODLINE: 'pdpDataForUpdateFoodLine',
  LOCALIZATION_SIGN_IN: 'localisationSignIn',
  LOCALIZATION_SIGN_UP: 'localisationSignUp',
  SET_FAV: 'setFav',
  LOAD_MORE_ORDER_HISTORY: 'LoadMoreOrderHistory',
  REORDER_CLICK: 'reorderClick',
  ELIGIBLE_STORE: 'eligibleStore',
  CURRENT_FAV_MENU: 'currentFavMenu',
  DROPOFF_ADDRESS: 'dropoffAddress',
  SET_LOCATION: 'setLocation',
  ADD_TO_ORDER: 'addToOrder',
  VIEW_PRODUCT: 'viewproduct',
  PRICE_LIMITS: 'priceLimits',
  ORDER_COMPLETE: 'orderComplete',
  IMAGE_VERSION: 'image_version',
  CUSTOMISE_ITEM_CART: 'customise_item_cart',
  NAV_URL: 'navUrl',
  SET_CART_CLEAR: 'setCartClear',
  FORMATTED_DELIVERY_ADDRESS_FILTERBY: 'formattedDeliveryAddressFilterBy',
  CHANGE_SCHEDULE_MODE: 'changeScheduleMode',
};

export const SESSION_STORAGE_KEYS = {
  INDULGENT_ADDONS_CART_CLICK: 'addOnsModalCartbtnClick',
  STORE_ID: 'storeId',
  DISPOSITION_TYPE: 'dispositionType',
  SET_ATTRIBUTE_PAYLOAD: 'setAttributePayload',
  USER_ID: 'userId',
  FORCED_SIGN_OUT: 'forcedSignOut',
  ORDER_TRACKING_CLICK_KFC_ACCOUNT: 'orderTrackingClickKFCAccount',
  PICKUP_ORDER_CANCELLED: 'pickupOrderCancelled',
  MARKETING_OPT_VAL: 'marketingOptInVal',
  SCHEDULE_DATE_EPOCH: 'scheduledDateInEpoch',
  CUSTOMISE_PDP_FLAG: 'customise_pdp_flag',
  SHOW_ORDER_NOW: 'showOrderNow',
  POST_PAYMENT_TYPE: 'postPaymentType',
  SHOW_SCHEDULE_REORDER: 'showScheduleOrder',
  CHANGE_SCHEDULE: 'changeScheduleMode',
  SCHEDULE_ORDER_CLICKED: 'ScheduleModeClicked',
  SHOW_SCHEDULE_DISPOSITION: 'showSheduleDisposition',
  IS_FUTURE_TIME_DATE_ORDER: 'isFutureTimeDateOrder',
  RETURN_TO_PAYMENT_OPTION_CHECKOUT: 'returnToPaymentOptionClicked',
};

export const PAYMENT_GATEWAYS = {
  BILLDESK: 'billdesk',
  PAYU: 'payu',
  BRAIN_TREE: 'braintree',
  PAY_GATE: 'paygate',
  GLOBAL_PAYMENTS: 'globalpayment',
  JAPAN_PAYMENTS: 'japanpayments'
};
export const PURCHASE_TYPE = {
  GIFT_CARD_PURCHASE: 'giftCardPurchase',
  ADD_HOPE_PURCHASE: 'addHopePurchase',
  GET_GIFT_CARD_DETAILS: 'getCardDetails',
};

export const PAYMENT_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  STATUS_TEXT: 'paymentStatus',
};

export const AMOUNT_VALIDATION_ERROR_CODES = {
  MINIMUM_AMOUNT: 'BSDE0027',
  MAXIMUM_AMOUNT: 'BSDE0028',
};

export const BRAND_PAGE_KEYS = {
  DRIVE_THRU: 'DRIVE-THRU',
  PICK_UP: 'PICK UP',
  DELIVERY: 'DELIVERY',
  FIND_OUT_MORE: 'FindOutMoreButton',
};

export const DOWNLOAD_PAGE_KEYS = {
  STRIPES: '3 stripes',
  DOWNLOAD_APP: 'Download on the App Store',
  GOOGLE_PLAY: 'GET IT ON Google Play',
  DOWNLOAD_APP_IMAGE: 'DownloadApp_Image',
};
export const CAROUSEL_RESPONSIVE_SETTINGS = [
  {
    breakpoint: 1950,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      swipeToSlide: true,
      variableWidth: false,
    },
  },
  {
    breakpoint: 1720,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      swipeToSlide: true,
      variableWidth: false,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      swipeToSlide: true,
      variableWidth: false,
    },
  },
  {
    breakpoint: 1000,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      swipeToSlide: true,
      variableWidth: false,
    },
  },
  {
    breakpoint: 880,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      swipeToSlide: true,
      variableWidth: false,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      swipeToSlide: true,
      variableWidth: true,
    },
  },
];

export const COUNTRY_CODE = {
  AU: 'AU',
  IN: 'IN',
  CA: 'CA',
  ZA: 'ZA',
  JP: 'JP',
  TH: 'TH',
};

export const DYNAMIC_IMPORT_CONFIG_KEY = {
  HOME_PAGE_CONSTANTS: 'HOME_PAGE_CONSTANTS',
  GLOBAL_STYLE_SCRIPTS: 'GLOBAL_STYLE_SCRIPTS',
};

export const PICKUP_COLLECTION_METHOD = {
  IN_STORE: 'in-store',
  DRIVE_THRU: 'drive-thru',
  CURB_SIDE: 'curb-side',
};

export const AUTH_CONSTANTS = {
  SKIPPED: 'skipped',
  TOKEN_EXPIRED: 'tokenExpired',
  CONTINUE_AS_GUEST: 'continueAsGuest',
  SIGN_IN: 'signIn',
  CONTINUE: 'continue',
  OOPS_WENT_WRONG: 'oopsWentWrong',
  RETAIN_CART_MESSAGE: 'retainCart',
};

export const CURRENT_FAV_MENU = 'currentFavMenu';
export const NOT_AVAILABLE = 'NA';
export const ACCOUNT_PATH = '/account';
export const ECOMMERCE = 'ecommerce';

export const FIELDS = 'fields';
export const TITLE = 'title';
export const CONTENT = 'content';
export const TOPICS = 'topics';
export const LINKTEXT = 'linkText';
export const INTERNALURL = 'internalurl';
export const DESCRIPTION = 'description';
export const HEADLINE = 'headline';
export const NAME = 'name';
export const BUTTONTEXT = 'buttonText';
export const IMAGE = 'image';
export const FILE = 'file';
export const URL = 'url';
export const MOBILEIMG = 'mobileImage';
export const DESKTOPIMG = 'desktopImage';
export const ICON = 'icon';
export const PHNUMBER = 'phoneNumber';
export const EMAIL_ADDRESS = 'eMailAddress';
export const CONTACTUS = 'CONTACT US';
export const GETHELP = 'Get Help';
export const NEARESTKFC = 'Nearest KFC';
export const CALLUS = 'Call us';
export const SHAREEXPERIENCE = 'SHARE YOUR EXPERIENCE';
export const FAQTOPICS = 'FAQ Topics';
export const ALL_TOPICS = 'All Topics';
export const VIEWALLFAQ = 'ViewAllFAQ';
export const BLACKLISTED_COUPONS = ['KUKDUKOO'];
export const SET_LOCATION = 'SET LOCATION';
export const WEBM = 'webm';
export const MP4 = 'mp4';
export const TEXT = 'text';
export const TELEPHONE = 'tel';
export const MASKINPUT = 'maskinput';
export const INPUT = 'input';
export const BUTTON_POSITION = {
  STANDARDTOP: 'standard-top',
  STANDARDBOTTOM: 'standard-bottom',
};

export const SIGNME_UP_BANNER_CONTAINER = 'SignMeUPBannerContainer';
export const LOYALTY_CRM_ITEMS = 'LoyaltyCrmItems';

export const SELECT_STORE_MSG = 'selectDifferentStore';
export const CART_SELECT_STORE_MSG = 'cartSelectDifferentStore';
export const FIND_NEAR_BY_STORE_CTA = 'findNearRestorant';
export const NO_LONGER_SERVER_TEXT = 'noLongerServeText';
export const IS_CLOSED_DUE_TO = 'isClosedDueTo';
export const SORRY = 'Sorry';
export const DISPOSITION_TYPES_TXT = 'dispositionType';
export const SEACHEDLOCATION = 'seachedLocations;';
export const EVERYDAY = 'EVERYDAY';
export const WEEKDAYNAME = 'dddd';
export const FREE = 'free';
export const CALORIES = 'calories';
export const UPDATED_TEXT = 'updated';
export const YYYY_MM_DD = 'YYYYMMDD';
export const RESIZE = 'resize';
export const BEFOREUNLOAD = 'beforeunload';
export const POPSTATE = 'popstate';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const STORE_CLOSE = 'storeClose';
export const CLOSED = 'CLOSED';
export const OPEN = 'OPEN';
export const STORE_CLOSED = 'store-closed';
export const STORE_OPEN = 'store-open';
export const PLUS_CODE = 'plus_code';
export const OK = 'OK';
export const KMS = 'km';

export const STORE_END_TIME = '2359';
export const STORE_OPEN_TIME = '0000';

export const JQUERY_URL = 'https://code.jquery.com/jquery-3.6.1.min.js';
export const JQUERY_SCRIPT_ID = 'jqueryScript';
export const BILLDESK_SCRIPT_ID = 'billdeskScript';
export const BILLDESK_SDK_URL = '/cscv/bdci.payment.js';

export const NAME_HALF_STRING_REGEX = /^[a-zA-Z ]{2,12}$/;
export const FULL_NAME_STRING_REGEX = /^[a-zA-Z ]{2,26}$/;
export const NUMBER_ONLY_REGEX = /^\d*$/;
export const SPACE_REGEX = /\s/g;
export const RESTRICT_WORDS_REGEX = /[^\d]/g;
//eslint-disable-next-line
export const RESTRICT_SPECIAL_CHARACTERS = /[&\/\\#, +@!^()$~%.'":*?<>{}]/g;

export const EMAIL_REGEX_SPL_REPET =
  /^[a-zA-Z0-9]+(?:[\.\_\-][a-zA-Z0-9]+)*\@[a-zA-Z]+([\.-]?[a-zA-Z]+)*(\.[a-zA-Z]{2,3})+$/; /* eslint-disable-line */
export const EMAIL_REGEX =
  /^(([^<>()[\]\\,;:\s@\\!`~#$%^&*+={}|'"?/]+(\.[^<>()[\]\\,;:\s@\\!`~#$%^&*+={}|'"?/]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_FORMAT_REGEX =
  /^[a-zA-Z0-9]+([a-zA-Z0-9\.\_\-]+)*\@[a-zA-Z0-9]+([\.-]?[a-zA-Z]+)*(\.[a-zA-Z]{2,15})+/; /* eslint-disable-line */ //pattern is suggested
export const FULL_NAME_REGEX = /^(?=.*[a-zA-Z].*)([a-zA-Z-']{1,100})$/;
export const DIGIT_RESTRICTION = /[a-z-']/i;
export const ACTIVITY_EVENTS = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
export const SCROLL_EVENT = 'scroll';
export const FULL_NAME_RESTRICTION = /[a-z-' ]/i;
export const CLICK_EVENT = 'click';
export const DAILY_CART_STATUS_CHECK_FLAG = 'dailyCartStatusCheckFlag';
export const MESSAGE = 'message';
export const ZERO_RESULTS = 'ZERO_RESULTS';
export const HTTPS_URL = 'https://';
export const GO_TO_ORDER_HISTORY = 'GoToOrderHistory';
export const DELIVERY_OPTION_DATA = ['selectOne', 'meetAtVehicle', 'handItToMe'];
export const NON_LOCALISED_GUEST = 'non lo-guest';
export const LOCALISED_GUEST = 'lo-guest';
export const NON_LOCALISED_USER = 'non lo-member';
export const LOCALISED_USER = 'lo-member';
export const SELECTED_EVENT_TYPE = 'selectOrderType';
export const PLP_PAGE = 'plp';
export const PROGRESS_PROVIDER_VALUES = {
  forThreeSEC: [0, 60, 100],
  forFourSEC: [0, 25, 50, 100],
  forFiveSEC: [0, 25, 60, 80, 100],
};

export const CHECKOUT_CONSTANTS = {
  DASH: '-',
  PLUS: '+',
  STAR: '*',
  LEAVE_AT_MY_DOOR: 'leaveatmydoor',
  IN_STORE: 'in-store',
  CURBSIDE: 'curbside',
  EMPTY_STRING: '',
  ERROR_ALERT: 'checkoutAlert',
  CURB_SIDE: 'curbside',
  HAND_IT_TO_ME: 'handittome',

  PRICE_DECIMAL_DIGIT: 2,
  PHONEPE_SWITCH_CONSTANTS: {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
  },
  FORM_FIELD_DEFAULT_VALUE: {
    value: '',
    errorMessage: '',
    error: false,
  },
  BEGIN_CHECKOUT_CLICK: 'BeginCheckoutClick',
  ADD_CONTACT_INFO_CLICK: 'AddContactInfoClick',
  PAY_TM_MERCHANT_ID_ERROR: 'payTmMerchantIdError',
  PAY_ON_ARRIVAL: 'payonarrival',
};

export const SHARED_CONSTANTS = {
  SEARCH_IN_CART: 'SEARCH_IN_CART',
  ONLY_IMAGE: 'Only image',
  DD_MM_YY_WITH_SLASH: 'DD/MM/YY',
  HH_MM: 'hh mm',
  HH_MM_A: 'hh:mma',
  H_MM_A: 'h:mmA',
  HH_A: 'hha',
  H_MM_a: 'h:mma',
  H_a: 'ha',

  FAVORITES_ORDERS_REGEX: /^(?!\s)[A-Za-z0-9 ]{1,50}$/,
  FAVORITES_ORDERS_REGEX_NEW: /^(?!\s)[A-Za-z0-9 ]+$/,

  APPLIED: 'APPLIED',
  UPDATE_CATERING_RETAIL_MENU_ALERT: 'UPDATE_CATERING_RETAIL_MENU_ALERT',

  OK: 'OK',
  NOT_FOUND: 'NOT_FOUND',
  ZERO_RESULTS: 'ZERO_RESULTS',
};

export const NETWORK_STATUS = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,
};

export const ANALYTICS_SHARED_CONSTANTS = {
  AUD: 'AUD',
  INR: 'INR',
  PDP_UPSELL: 'PDP - Upsell',
  SR_VALUE: 'SR123',
  SELECT_ITEM_EVENT: 'select_item',
  ADD_TO_CART: 'add_to_cart',
  WE_THINK_YOU_WILL_LOVE_THESE: 'we think you’ll love these',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_PAYMENT: 'add_payment_info',
  ONLINE_STORE: 'Online Store',
  REMOVE_TO_CART: 'remove_from_cart',
  CART_CROSS_SELL: 'Cart - Cross Sell',
  LOCALISATION_FINISH: 'localisationFinish',
  LOCALISATION_TYPE: {
    MANUAL: 'manual',
  },
};

export const REORDER_CONSTANTS = {
  ACCOUNT_FAVOURITES: 'account - my favourites',
  ACCOUNT_ORDER_HISTORY: 'account - order history',
  HOME_FAVOURITES: 'home - my favourites',
  REORDER: 'reorder',
};

export const DATE_FORMATS = {
  MMMM_D: 'MMMM D', //Apr 5
  YYYY_MM_DD: 'YYYY-MM-DD', //2022-04-15
  M_D_YYYY_h_mm_ss_A: 'M/D/YYYY h:mm:ss A', //3/12/2022 5:24 PM
  h_mm_A: 'h:mm A', // 5:27 AM
  YYYY_MM_DD_hh_mm: 'YYYY-MM-DD hh:mm', // 2022-11-28 20:12
  H_MM_A: 'h:mma', //3:29pm
  H_MM_A_CAPS: 'h:mmA', //3:29PM
  HH_mm: 'HH:mm', //20:12
  HH_MM: 'hh mm', //12 34
  hh_mm: 'hh:mm', //11:45
  hh_mm_A: 'hh:mm A', // 04:45 AM
  LT: 'LT',
  HHmm: 'HHmm',
  YYYY_MM_DD_h_mm_A: 'YYYY-MM-DD h:mm A', //2022-12-14 3:23 PM
  MMMM_D_YYYY: 'MMMM D, YYYY',
  H_MM_AA: 'h:mm a', //3:23 am
  DD_MMMM_YYYY: 'DD MMMM YYYY',
  dd_DD_MM_YYYY_hh_mm_A: 'dd DD-MM-YYYY hh:mm A',
  ddd_DD_MM_YYYY_hh_mm_ss_A: 'dd DD-MM-YYYY hh:mm:ss A',
  day: 'day',
  minutes: 'minutes',
  minute: 'minute',
  MMMM_d_YYYY: 'MMMM d, yyyy',
  GMT_FORMAT: 'GMT+00:00',
  UTC: 'UTC',
  lll: 'lll',
  DDDD_D_MMMM_A: 'dddd D MMMM YYYY, hh:mm a',
  D_MMMM_A: 'D MMMM YYYY, hh:mm a',
  dddd_MMMM_D: 'dddd MMMM D',
  DDDD_MMMM_D_HH_MM_A: 'dddd MMMM D, hh:mm a',
  MMMM_D_HH_MM_A: 'MMMM D, hh:mm a',
  DDDD: 'dddd',
  MMMM: 'MMMM',
  D: 'D',
};
export const IMAGE_SIZE_CONSTANTS = {
  BROWSE_CATEGORIES: {
    FIRST_ELE_HEIGHT_DESK: 600,
    FIRST_ELE_WIDTH_DESK: 800,
    REST_ELE_HEIGHT_DESK: 450,
    REST_ELE_WIDTH_DESK: 600,

    FIRST_ELE_HEIGHT_MOB: 300,
    FIRST_ELE_WIDTH_MOB: 400,
    REST_ELE_HEIGHT_MOB: 225,
    REST_ELE_WIDTH_MOB: 300,
  },
  APP_DETAIL_SECTION_STRIPES: {
    HEIGHT: 25,
    WIDTH: 40
  },
  APP_DETAIL_SECTION_IMAGE: {
    HEIGHT: 465,
    WIDTH: 446
  },
  APPLE_GOOGLE_STORE: {
    HEIGHT: 90,
    WIDTH: 266
  },
  SIGNME_UP_BANNER_STRIPES: {
    HEIGHT: 19,
    WIDTH: 39
  },
  SIGNME_UP_BANNER_KFC_BOX: {
    HEIGHT: 190,
    WIDTH: 225
  },
  HEADER_SIGN_IN: {
    HEIGHT: 36,
    WIDTH: 36
  },
  BRAND_COMPONENT_DELIVERY:{
    HEIGHT: 188,
    WIDTH: 130
  },
  BRAND_COMPONENT_PICKUP: {
    HEIGHT: 183,
    WIDTH: 140
  },
  BRAND_COMPONENT_DRIVE: {
    HEIGHT: 175,
    WIDTH: 148
  },
  FOOTER_FIND_STORE: {
    HEIGHT: 15,
    WIDTH: 11
  },
  FOOTER_KFC_BUCKET: {
    HEIGHT: 120,
    WIDTH: 120
  },
  FOOTER_SOCIAL_ICONS: {
    HEIGHT: 60,
    WIDTH: 60
  },
  PDP_MODIFIER: {
    PDP_MODF_IMAGE_VIEWPORT_WIDTH_MOBILE: 25,
    PDP_MODF_IMAGE_VIEWPORT_WIDTH_DESKTOP: 7,
  },
  PDP_MAIN_CONTAINER: {
    PDP_IMAGE_HEIGHT_MOBILE: 300,
    PDP_IMAGE_HEIGHT_DESKTOP: 900,
    PDP_IMAGE_WIDTH_MOBILE: 400,
    PDP_IMAGE_WIDTH_DESKTOP: 1200,
  },
  CART_ITEM: {
    CART_IMAGE_WIDTH_DESKTOP: 200,
    CART_IMAGE_HEIGHT_DESKTOP: 150,
    CART_IMAGE_WIDTH_MOBILE: 100,
    CART_IMAGE_HEIGHT_MOBILE: 75,
  },
  INDULGENT_ITEM: {
    ITEM_IMAGE_HEIGHT_MOBILE: 225,
    ITEM_IMAGE_HEIGHT_DESKTOP: 165,
    ITEM_WIDTH_DESK_PRCNT: 40,
    INDULGENT_IMAGE_WIDTH: 300,
    INDULGENT_IMAGE_HEIGHT: 225,
  },
  ORDER_HISTORY_ITEM: {
    ITEM_IMAGE_HEIGHT_MOBILE: 80,
    ITEM_IMAGE_WIDTH_MOBILE: 105,
    ITEM_IMAGE_HEIGHT_DESKTOP: 115,
    ITEM_WIDTH_MOB_PRCNT: 32,
    ITEM_WIDTH_DESK_PRCNT: 8,
  },
  FAV_ORDER_ITEM: {
    ITEM_IMAGE_HEIGHT_MOBILE: 75,
    ITEM_IMAGE_WIDTH_MOBILE: 100,
    ITEM_IMAGE_HEIGHT_DESKTOP: 108,
    ITEM_IMAGE_WIDTH_DESKTOP: 144,
    ITEM_WIDTH_MOB_PRCNT: 27,
    ITEM_WIDTH_DESK_PRCNT: 8,
  },
  RECOMMENDED_ITEM: {
    ITEM_IMAGE_HEIGHT_MOBILE: 300,
    ITEM_IMAGE_WIDTH_MOBILE: 400,
    ITEM_IMAGE_HEIGHT_DESKTOP: 600,
    ITEM_IMAGE_WIDTH_DESKTOP: 800,
  },
  MARKETING_ITEMS: {
    ITEM_IMAGE_HEIGHT_MOBILE: 210,
    ITEM_IMAGE_WIDTH_MOBILE: 246,
    ITEM_IMAGE_HEIGHT_DESKTOP: 580,
    ITEM_IMAGE_WIDTH_DESKTOP: 680,
  },
  FINDAKFC_ITEMS: {
    FINDAKFC_IMAGE_HEIGHT_MOBILE: 328,
    FINDAKFC_IMAGE_WIDTH_MOBILE: 328,
    FINDAKFC_IMAGE_HEIGHT_DESKTOP: 580,
    FINDAKFC_IMAGE_WIDTH_DESKTOP: 580,
  },
  ADD_HOPE_THANKYOU_ITEMS: {
    THANKYOU_IMAGE_HEIGHT_MOBILE: 328,
    THANKYOU_IMAGE_WIDTH_MOBILE: 328,
    THANKYOU_IMAGE_HEIGHT_DESKTOP: 580,
    THANKYOU_IMAGE_WIDTH_DESKTOP: 580,
  },
  ADDHOPE_ITEMS: {
    ADDHOPE_IMAGE_HEIGHT_MOBILE: 170,
    ADDHOPE_IMAGE_WIDTH_MOBILE: 320,
    ADDHOPE_IMAGE_HEIGHT_DESKTOP: 372,
    ADDHOPE_IMAGE_WIDTH_DESKTOP: 1220,
    BUCKET_IMAGE_WIDTH_DESKTOP: 162,
    BUCKET_IMAGE_HEIGHT_DESKTOP: 130,
    SNAPSCAN_IMAGE_HEIGHT_DESKTOP: 100,
    SNAPSCAN_IMAGE_WIDTH_DESKTOP: 100,
  },
  GIFT_CARD_ITEMS: {
    BANNER_WIDTH_DESKTOP: 580,
    BANNER_HEIGHT_DESKTOP: 348,
    BANNER_WIDTH_MOBILE: 355,
    BANNER_HEIGHT_MOBILE: 205,
  },
  MENU_LINK_IMAGES: {
    MENU_IMAGE_HEIGHT_MOBILE: 115,
    MENU_IMAGE_WIDTH_MOBILE: 153,
  }
};

export const BrandPageConstant = {
  BRAND_PAGE_HERO: 'brandPageHero',
  INTROCOPY: 'introcopy',
  FEATURE: 'feature',
  KEY_FIGURES: 'keyfigures',
  FEATURE_INTERACTIVE: 'featureinteractive',
  SECTION_LINK_CARDS: 'sectionlinkcards',
  SECTION_LINK_HERO: 'sectionlinkHero',
  QUOTES: 'quote',
  KFC_TIMELINE_BANNER: 'KFC_TIMELINE_BANNER',
  BRAND_PAGE: 'brandPage',
  BRAND_PAGE_TEXT: 'brandPageText',
  STATIC_BRAND_PAGE: 'staticBrandPage',
};
export const DELIVERY_PARTNER = {
  UBERDIRECT: 'uberdirect',
  SKEDADEL: 'skedadel',
  DOORDASH: 'doordash',
  KFC_GENERIC_MSG: 'kfc',
};

export const SELECTED_FAQ_ITEM = 'SelectedFaqItem';
export const MOUSE_DOWN = 'mousedown';
export const EMERGENCY_SHUTDOWN = 'EMERGENCY_SHUTDOWN';
export const REDIRECTING = 'Redirecting...';
export const EQUALS = 'equals';
export const MDMID = 'mdmId';
export const EXISTS = 'exists';
export const ENTER = 'Enter';
export const CLOSE = 'Close';
export const APP_SOURCE_CHANNEL = 'web';
export const TECHNICAL_SHUTDOWN = 'TECHNICAL_SHUTDOWN';
export const STORE_NOT_ALIVE = 'STORE_NOT_ALIVE';
export const HOLIDAY_CLOSED = 'HOLIDAY_CLOSED';
export const HOLIDAY_OPEN = 'HOLIDAY_OPEN';
export const AFTER_NOON = 'afterNoon';
export const HOLIDAYS = 'Holidays';
export const CAUTION_NOTICE_URL = '/about-kfc/caution-notice';
export const TENANT_DELIVERY_ADDRESS_MAPPING = 'deliveryAddressMapping';
export const FROM_CONTACT = 'fromContact';
export const DOORDASH_NOT_SERVICEABLE = 'DOORDASH_NOT_SERVICEABLE ';
export const REMOVE_ONE_OR_MORE_SPACE = /\s+/g;
export const MARKETING_OPT_IN = 'marketingOptIn';
export const TERMS_AND_CONDITIONS ='tnc';
export const MARKETING_OPT_EMAIL = 'marketingOptEmail';
export const MARKETING_OPT_PHONE = 'marketingOptPhone';

export const COUPON_IMAGE_PATH = '/images/offers/';
export const COUPON_IMAGE_NAME = /\.[^/.]+$/;
export const PREVIEW_PDF = 'previewPDF';
export const TIME_BASED_CATEGORY_MENU_NAME = '%category%';
export const CUSTOM_DRIVER_TIPPING_AMOUNT_REGEX = /^(\d*\.{0,1}\d{0,2}$)/;
export const CUSTOM_ADD_HOPE_DONATE_AMOUNT_REGEX = /^\d*$/;
export const ADD_HOPE_BUTTON = 'Add Donation';
export const TIP_AMOUNT_PLACEHOLDER = 'Tip Amount:';
export const ADD_DONATION_PLACEHOLDER = 'Donation Amount:';
export const BACKSPACE_KEY = 'Backspace';
export const DELETE_KEY = 'Delete';
export const CONTACT_DETAIL_ERROR_TITLE = 'Contact details missing';
export const ORDER_TIME_STAMP = '##ordertimestamp##';
export const CUSTOMER_NAME = '##customerName##';
export const DAT_DATE_TIME_MONTH = '##fullLengthDate##';
export const ONLY_TIME = '##shortLengthDate##';
export const FIND_STAMP = 'MonthStamp';
export const COUPON_ERROR = 'couponErrMsg';
export const CONFIRM_AMOUNT_BUTTON = 'Confirm Amount';
export const CONFIRM_AMOUNT_PLACEHOLDER = 'Gift card Amount:';
export const NEED_HELP_CONSTANTS = {
  CALL_SUPPORT_CONTENT: 'Callsupportcontent',
  GET_BACK: 'GetBack',
};
export const TOTAL_AMOUNT_REPLACE_TEXT = '##totalAmount##';
export const DYNAMIC_MENU_CONSTANTS = {
  CATEGORY: 'category',
  ITEM: 'item',
  TIME_SPECIFIC_CATEGORY: 'timeSpecificCategory',
  ALERT_15MINS: 15,
  AlERT_0MIN: 0,
};
export const VOUCHER = 'voucher';
export const UNIT_BY_ADDRESS = 'unit';
export const SUB_PREMISE_FILTER = ['unit','#'];
export const FUNCTION_TYPE ='function';
export const FINGER_LICKIN = 'Finger_lickIn';
export const checkoutUserOptionPayload = {
  HAND_IT_TO_ME:'meet_at_door',
  LEAVE_AT_MY_DOOR:'leave_at_door'
}
export const HAND_IT_TO_ME_TEXT = 'Hand it to me';
export const SAVED_CARD_CVV =  'savedCardCvv';
export const AMEX = 'amex';
export const BRAZE_COOKIES='C0004';
export const STREET_NUMBER = 'street_no';
export const SAVED_CARDS = 'savedCards';
export const SHUTDOWNS = ['HOLIDAY_CLOSED', 'TECHNICAL_SHUTDOWN', 'EMERGENCY_SHUTDOWN', 'NOT_WITHIN_OPERATING_HOURS'];
export const NAME_ON_CARD_REGEX = 'nameOnCardRegex';
export const CURRENT_STATUS = 'currentStatus';
export const STATUS_API_CALL = 'statusAPICalled';
// Default Export
export default SharedConstants;
