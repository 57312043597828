import React, { createContext, useReducer } from 'react';
import { orderStateReducer } from 'context/reducers';
import { emptyFunction } from "common/utilities/utils";

const UserOrderContext = createContext({
  userOrderState: {},
  userOrderStateDispatch: emptyFunction,
});

const UserOrderContextProvider = ({ children }) => {
  const [userOrderState, dispatchContext] = useReducer(orderStateReducer, {
    dispositionStateData: {
      contentData: {},
    },
    selectedLanguage: '',
    searchStoreData: {
      contentData: {},
      userAddress: '',
    },
    scheduleOrderData: {
      changeFlow: false,
      contentData: {},
      storeInfo: {},
      selectedDateTimeInfo: {
        time: '',
        interval: '',
        date: '',
      },
      selectContentData: {
        savedDate: '',
        savedTime: '',
      },
    },
    confirmUpsizeName: 'none',
    selectedTile: {},
    dispositionOld: {
      type: '',
      store: '',
      time: '',
      date: '',
      interval: '',
      deliveryAddress: '',
      deliveryAddressLine: '',
    },
    dispositionNew: {
      type: '',
      date: '',
      store: '',
      time: '',
      interval: '',
      deliveryAddressLine: '',
      deliveryAddress: '',
      selectedTime: '',
    },
    cartAlertData: {
      showCartAlert: false,
    },
    pdpScreenCommons: {
      expandType: 'all',
      modGroupIdsUpdated: [],
      currentAccordian: '',
    },
    cartStateData: {
      showCartState: false,
    },
    cartItems: {
      currentEditItem: {},
      itemLocations: [],
    },
    deliveryAddress: '',
    deliveryAddressLine: '',
    selectedAddress: {},
    showLanguageSelector: false,
    addToCart: false,
    productItemId: '',
    categoryId: '',
    oldProduct: {},
    isLocalized: false,
    customizable: false,
    catalogs: '',
    fromPdpPage: false,
    showCouponCopied: false,
    showCartClearAlert: false,
    modalToOpenOnConfirm: '',
    modalOpenFrom: '',
    currentStoreInfo: null,
    minipdpData: {
      itemId: '',
      fromFlag: '',
      foodLineId: '',
    },
    cartEditClicked: false,
    couponErrorMessage: '',
    tenderInfo: { name: '', payload: {} },
    isPaymentAdded: false,
    paymentFormPayload: {},
    showCartErrorModal: false,
    forceReload: false,
    sopacPDPCustomization: {
      showPDPCustomization: false,
      showCustomizationModId: '',
      showCustomizationModGrpId: '',
      showCustomizationModGrpName: '',
      updatedPrice: 0,
    },
    pdpItemCustomType: false,
    pdpItemUpsizeId: '',
    showLocationConfirmModal: false,
    hideDispositionBar: false,
    hideAccountPageHeaders: false,
    pdpItemL3Data: [],
    pdpPayloadData: {},
    showToastAlert: false,
    resetCustomization: false,
    setLocationClicked: false,
    removeItemCart: false,
    pdpDataForUpdateFoodLine: {
      itemId: '',
      flag: '',
      foodLineId: '',
    },
    startOrderBtnClicked: false,
    orderModeBrowseMenuBtnClicked: false,
    isPdpPageActive: false,
    showNotInRadiusError: false,
    notInRadiusRestaurant: null,
    eligibleStore: [],
    pdpAddtoCartConfirmButtonDisabled: {},
    isFromRecommendedSectionItemClick: false,
    alertModalData: {
      flag: false,
      pathname: '',
      title: '',
      contentMsg: '',
    },
    findAKFC: false,
    enableASAPScheduling: false,
    showOrderNow: false,
    showScheduleOrder: true,
    showOrderNowReorder: false,
    existUserIsSignIn: false,
    showScheduleOrderReorder: false,
    isFutureTimeDateOrder: false,
    redirectionOrder: '',
    pdpPromoPage: false,
    autoDetectionClicked: false,
    showLocationBlockBanner: false,
    reorderFlag: {
      reorderModalOpen: false,
      fromReorderFlag: false,
      storeChanged: false,
    },
    menuNavRestricted: {
      flag: '',
      pathnameToNavigate: '',
    },
    timeBasedAlertModalData: {},
    timeBasedCetegory: {},
    scheduleModalOpen: false,
    placeOrderButtonEnabled: true,
    orderSummaryModalClose: true,
    changeScheduleModeFlag: false,
    ScheduleModeClicked: false,
    showSheduleDisposition: false,
    selectedDeliveryAddress: '',
    deliveryAddressLineVal: '',
  });

  return (
    <UserOrderContext.Provider value={{ userOrderState, userOrderStateDispatch: dispatchContext }}>
      {children}
    </UserOrderContext.Provider>
  );
};

export { UserOrderContext, UserOrderContextProvider };
